import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useFormikContext } from 'formik';

import AWSAutomaticAuthentication from 'archive/components/aws/AWSAutomaticAuthentication';
import { FormikFormGroup } from 'components/common';
import { Backend, S3BackendSettings } from 'archive/types';

import AWSAuthenticationTypeFormGroup from './aws/AWSAuthenticationTypeFormGroup';
import AWSKeySecretFieldGroup from './aws/AWSKeySecretFieldGroup';

const AuthWrapper = styled.div(({ theme }) => css`
  margin: 40px 0 21px 30px;
  padding: 15px 0 3px 15px;
  border-left: 3px solid ${theme.colors.gray[80]};
  overflow: hidden;
`);
const KEYSECRET_AUTH_TYPE = 'keysecret';
const AUTOMATIC_AUTH_TYPE = 'automatic';

const ArchiveBackendS3FieldGroup = () => {
  const { values: { id, settings }, setFieldValue } = useFormikContext<Backend>();
  const s3Settings: S3BackendSettings = settings;
  const currentAuth = s3Settings.aws_access_key_id ? KEYSECRET_AUTH_TYPE : AUTOMATIC_AUTH_TYPE;
  const [authenticationType, setAuthenticationType] = useState(currentAuth);

  const isType = (type) => {
    return authenticationType === type;
  };

  useEffect(() => {
    if (s3Settings.aws_secret_access_key && 'is_set' in s3Settings.aws_secret_access_key) {
      const isSet = s3Settings.aws_secret_access_key.is_set;
      setFieldValue('settings.aws_secret_access_key', isSet ? { keep_value: true } : undefined);
    }

    return () => {
      setFieldValue('settings.bucket', undefined);
      setFieldValue('settings.endpoint_uri', undefined);
      setFieldValue('settings.aws_access_key_id', undefined);
      setFieldValue('settings.aws_secret_access_key', undefined);
      setFieldValue('settings.aws_assume_role_arn', undefined);
      setFieldValue('settings.spool_directory', undefined);
    };
  }, [setFieldValue]);

  const _handleAuthenticationTypeChange = (option: string) => {
    if (option === AUTOMATIC_AUTH_TYPE) {
      setFieldValue('settings.aws_access_key_id', undefined);
      setFieldValue('settings.aws_secret_access_key', undefined);
    }

    setAuthenticationType(option);
  };

  return (
    <>
      <FormikFormGroup name="settings.endpoint_uri"
                       type="text"
                       label="S3 Endpoint URL"
                       help="Your S3 Endpoint URL, only required if you want to override the default endpoint."
                       labelClassName=""
                       wrapperClassName="" />
      <AWSAuthenticationTypeFormGroup updateAuthenticationType={_handleAuthenticationTypeChange} currentType={authenticationType} />
      {isType(AUTOMATIC_AUTH_TYPE) && (
        <AuthWrapper>
          <AWSAutomaticAuthentication />
        </AuthWrapper>
      )}
      {isType(KEYSECRET_AUTH_TYPE) && (
        <AuthWrapper>
          <AWSKeySecretFieldGroup />
        </AuthWrapper>
      )}
      <FormikFormGroup name="settings.aws_assume_role_arn"
                       type="text"
                       label="AWS Assume Role (ARN) (optional)"
                       placeholder="arn:aws:sts::123456789012:assumed-role/some-role"
                       help="Amazon Resource Name with required cross account permission."
                       labelClassName=""
                       wrapperClassName="" />
      <FormikFormGroup name="settings.bucket"
                       type="text"
                       label="S3 Bucket Name"
                       help="Your S3 Bucket name."
                       labelClassName=""
                       required
                       wrapperClassName=""
                       disabled={!!id} />
      <FormikFormGroup name="settings.spool_directory"
                       type="text"
                       label="Spool directory"
                       placeholder="/tmp/s3-spool"
                       help="Spool directory for this backend."
                       labelClassName=""
                       required
                       wrapperClassName="" />
    </>
  );
};

export default ArchiveBackendS3FieldGroup;
